.card {
    width: 100%;
    cursor: pointer;
    margin: 10px 0;
    padding: 0px 15px;
    height: 100%;
}
.card:hover,
.card:active{
    background: rgba(0, 0, 0, 0.1);
}
.avatar {

}
.containerCard{
    width: 100%;
    display: inline-flex
}
@media(min-width:769px){
    .card {
        width: 100%;
        cursor: pointer;
        margin: 10px 0px;
    }
}
