.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}

.imgupload{
    background-image: url("../../../assets/img/add_photo-512.png");
    background-size: 150px 150px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.bottom-aligned {
    display: flex;
    align-items: flex-end;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}