                                .cardReporte{
    cursor: pointer;
}
.cardReporte:hover{
    background-color: rgba(25, 158, 218, .1);
}
.cardReporte:active{
    background-color: rgba(25, 158, 218, .3); 
}

.tabla_utilidad tr th td{
    font-size: 12px !important;
}