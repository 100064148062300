@charset "utf-8";
/* CSS Document */

@font-face {
    font-family: digital;
    src: url(digital-7.ttf);
}
@font-face {
    font-family: bebas;
    src: url(BebasNeue.ttf);
}
@import url('https://fonts.googleapis.com/css?family=Fjalla+One|Odibee+Sans|Orbitron&display=swap');
.fontdigital{
	font-family: 'Orbitron'; 
	font-size: 36px; 
	font-weight: bold;
}
@media (max-width: 720px) {
	.fontdigital{
		font-family: 'Orbitron'; 
		font-size: 36px; 
		font-weight: bold;
	}
	.fontdigital2{
		font-family: 'Orbitron';
		font-size: 30px; 
		font-weight: bold;
	}
	.labelbebas{
		font-family: "Fjalla One";
		font-size: large;
		color:#FFFFFF;
	}
	.fndhead{
		border-radius: 20px 20px 0px 0px;
		background: #D1D6DC;	
	}
	
	.fndhead{
		border-radius: 20px 20px 20px 20px;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b2b2b2+2,ededed+8,b2b2b2+47,b2b2b2+53,ededed+94,b2b2b2+99 */
	background: rgb(178,178,178); /* Old browsers */
	background: -moz-linear-gradient(left, rgba(178,178,178,1) 2%, rgba(237,237,237,1) 8%, rgba(178,178,178,1) 47%, rgba(178,178,178,1) 53%, rgba(237,237,237,1) 94%, rgba(178,178,178,1) 99%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(178,178,178,1) 2%,rgba(237,237,237,1) 8%,rgba(178,178,178,1) 47%,rgba(178,178,178,1) 53%,rgba(237,237,237,1) 94%,rgba(178,178,178,1) 99%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(178,178,178,1) 2%,rgba(237,237,237,1) 8%,rgba(178,178,178,1) 47%,rgba(178,178,178,1) 53%,rgba(237,237,237,1) 94%,rgba(178,178,178,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b2b2b2', endColorstr='#b2b2b2',GradientType=1 ); /* IE6-9 */
	}
	.etiquetas{
		width:445px; 
		padding-left:25px; 
		float:left; 
		align:center;
		margin-top:7px;
	}
	
	.etiquetas2{
		width:425px; 
		padding-left:5px; 
		float:left; 
		align:center;
	}
	.areatotalizador{
		background: #D1D6DC;
		padding: 15px; 
		width: 220px;
	}
	
	#body{
		width: 370px;
		height:570px;
		padding-top:18px;
		padding-left:18px;
		padding-right:18px;
		align-content:center;
		margin:0px auto;
		float:left;
		/*background-color:#B5B5B5;*/
		}
		
	#body2{
		width: 430px;
		height:288px;
		padding-top:21px;
		padding-left:21px;
		padding-right:21px;
		align-content:center;
		margin:0px auto;
		float:left;
		/*background-color:#B5B5B5;*/
		}
	#body3{
		width: 310px;
		height:547px;
		padding-top:21px;
		padding-left:15px;
		padding-right:10px;
		align-content:center;
		margin:0px auto;
		float:left;
		/*background-color:#B5B5B5;*/
		}
		
	div#left{
		width: 155px;
		height:165px;
		float:left;
		background-color:#000000;
		}
	div#leftxx{
		width: 180px;
		height:165px;
		float:left;
		background-color:#000000;
		}
	div#leftxx_2{
		width: 280px;
		height:165px;
		float:left;
		background-color:#000000;
		}
		div#left2r{
		width: 80px;
		height:165px;
		background-color:#000;
		}
	div#center{
		width: 120px;
		float: left;
		height:165px;
		flex-wrap:wrap;
		align-content:center;
		vertical-align: middle;
		text-align: center;
	
		}
	div#center_i3{
		width: 90px;
		float: left;
		flex-wrap:wrap;
		align-content:center;
		vertical-align: middle;
		text-align: right; 
		background_color:#000000;
		margin-top:2%; 
		margin-bottom:2%;
		}
	div#rigth{
		width: 190px;
		height:165px;
		background-color:#000000;
		float:left;
		}
	.cuadnum{
		background-color:#00FFF3;
		padding:2px;
		margin:10px;
		text-align:center;
		font-family: 'Orbitron'; 
		font-size: 26px; 
		font-weight: bold;
		}
	.cuadnum_uno{
		padding:2px;
		margin:20px;
		height:120px; 
		color:#FFFFFF;
		text-align:center;
		font-family:"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif; 
		font-size: 50px; 
		font-weight: bold;
		}
	div#rigth2{
		width: 150px;
		background-color:#000;
		float:left;
		}
		div#rigth2_4{
		width: 115px;
		background-color:#000;
		float:left;
		}
	div#left2{
		width: 150px;
		background-color:#000;
		float:left;
		}
	div#left2_4{
		width: 118px;
		background-color:#000;
		float:left;
	}
	div#left2b1{
		width: 150px;
		float:left;
		height:228px
	
		}
	div#lefto{
		/*width: 100px;*/
		float:left;
		padding:17px;
		border-radius: 20px 20px 20px 20px;
		border-style:1px solid;
		border:#00FFF3;
		}
	div#left2b1_4{
		width: 90px;
		float:left;
		}
		div#left2b1b{
		width: 150px;
		float:left;
		}
	div#left2b13{
		width: 150px;
		float:left;
		}
	div#left2b13_4{
		width: 115px;
		float:left;
		}
	div#left2b{
		width: 150px;
		height:170px;
		padding-top: 20px;
		}
	div#left2b_4{
		width: 117px;
		}
		div#left2bb{
		width: 150px;
		}
		div#left2bb4{
		width: 120px;
		}
	div#left2b3{
		width: 150px;
		}
	div#left2b3_4{
		width: 05px;
		}
	.oilall {
		
		}
	.estado{
		/*background-color:#0062A9;*/
		color:#FFFFFF;
		font-weight:bolder;
		font-family:'Fjalla One', "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", monospace;
		font-size:16px;
		text-align:center;
		pading:10px;
		}
	.estado2{
		background-color:#0062A9;
		color:#FFFFFF;
		font-weight:bolder;
		font-family:'Fjalla One', "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", monospace;
		font-size:17px;
		text-align:center
		}
	.estado2b{
		background-color:#F00000;
		color:#FFFFFF;
		font-weight:bolder;
		font-family:'Fjalla One', "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", monospace;
		font-size:17px;
		text-align:center
		}
	div#center2{
		width: 30px;
		height:45px;
		background-color:#FFFFFF;
		align-content:center;
		float:left;
		}
	div#center3{
		width: 170px;
	
		/*margin-left:11px;*/
		align-content:center;
		float:left;
		background: #000000;	}
	.cuadnum2{
		background-color:#00FFF3;
		padding:4px;
		margin:10px;
		text-align:center;
		font-family: 'Digital-7'; 
		font-size: 18px; 
		font-weight: bold;
		}
	.cuadnum4{
		background-color:#00FFF3;
		padding:2px;
		margin:0px;
		text-align:center;
		font-family: 'Digital-7'; 
		font-size: 11px; 
		font-weight: bold;
		}
	.totalizador {
		width:425px; 
		padding-left:5px; 
		float:left; 
		align:center;
		margin-top:7px;
	}
	.totalizador4 {
		width:250px; 
		float:left; 
		margin-top:7px;
	}
	.lablestext{
		color:#FFF;
		font-weight:bolder;
		font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
		font-size:12px;
		margin-left:5px;
		}
	.surticara{
		color:#FFF;
		font-weight:bolder;
		font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
		font-size:45px;
		text-align:center;
		}
	.surticara4{
		color:#FFF;
		font-weight:bolder;
		font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
		font-size:80px;
		text-align:center;
		}
	.surticara2{
		color:#FFF;
		font-family:"Fjalla One", "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
		font-size:25px;
		text-align:center;
		}
}

@media(min-width:721px){
	.fontdigital2{
		font-family: 'Orbitron';
		font-size: 30px; 
		font-weight: bold;
	}
	.labelbebas{
		font-family: "Fjalla One";
		font-size: large;
		color:#FFFFFF;
	}
	.labelbebas_2{
		font-family: "Fjalla One";
		font-size: 13px;
		color:#FFFFFF;
	}
	.fndhead{
		border-radius: 20px 20px 0px 0px;
		background: #D1D6DC;	
	}
	
	.fndhead{
		border-radius: 20px 20px 0px 0px;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b2b2b2+2,ededed+8,b2b2b2+47,b2b2b2+53,ededed+94,b2b2b2+99 */
	background: rgb(178,178,178); /* Old browsers */
	background: -moz-linear-gradient(left, rgba(178,178,178,1) 2%, rgba(237,237,237,1) 8%, rgba(178,178,178,1) 47%, rgba(178,178,178,1) 53%, rgba(237,237,237,1) 94%, rgba(178,178,178,1) 99%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(178,178,178,1) 2%,rgba(237,237,237,1) 8%,rgba(178,178,178,1) 47%,rgba(178,178,178,1) 53%,rgba(237,237,237,1) 94%,rgba(178,178,178,1) 99%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(178,178,178,1) 2%,rgba(237,237,237,1) 8%,rgba(178,178,178,1) 47%,rgba(178,178,178,1) 53%,rgba(237,237,237,1) 94%,rgba(178,178,178,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b2b2b2', endColorstr='#b2b2b2',GradientType=1 ); /* IE6-9 */
	}
	.etiquetas{
		width:445px; 
		padding-left:25px; 
		float:left; 
		align-content:center;
		margin-top:7px;
	}
	
	.etiquetas2{
		width:425px; 
		padding-left:5px; 
		float:left; 
		align-content:center;
	}
	.areatotalizador{
		background: #D1D6DC;
		padding: 15px; 
		width: 220px;
	}
	
	#body{
		width: 430px;
		height:288px;
		padding-top:18px;
		padding-left:18px;
		padding-right:18px;
		align-content:center;
		margin:0px auto;
		float:left;
		/*background-color:#B5B5B5;*/
		}
		
	#body2{
		width: 430px;
		height:288px;
		padding-top:21px;
		padding-left:21px;
		padding-right:21px;
		align-content:center;
		margin:0px auto;
		float:left;
		/*background-color:#B5B5B5;*/
		}
	#body3{
		width: 430px;
		height:255px;
		padding-top:21px;
		padding-left:21px;
		padding-right:21px;
		align-content:center;
		margin:0px auto;
		float:left;
		/*background-color:#B5B5B5;*/
		}
		
	div#left{
		width: 155px;
		height:165px;
		float:left;
		background-color:#000000;
		}
	div#left_2{
		width: 145px;
		height:133px;
		float:left;
		background-color:#000000;
		}
	div#leftxx{
		width: 145px;
		height:135px;
		float:left;
		background-color:#000000;
		}
		div#left2r{
		width: 80px;
		height:165px;
		background-color:#000;
		}
	div#center{
		width: 120px;
		float: left;
		height:165px;
		flex-wrap:wrap;
		align-content:center;
		vertical-align: middle;
		text-align: center;
		}
	div#center_2{
		width: 100px;
		float: left;
		height:130px;
		flex-wrap:wrap;
		align-content:center;
		vertical-align: middle;
		text-align: center;
		}
	div#center_i3{
		width: 80px;
		float: left;
		flex-wrap:wrap;
		align-content:center;
		vertical-align: middle;
		text-align: right; 
		background-color:#000000;
		margin-top:2%; 
		margin-bottom:2%;
		}
	div#rigth{
		width: 155px;
		height:135px;
		background-color:#000000;
		float:left;
		}
	div#rigth_2{
		width: 145px;
		height:133px;
		background-color:#000000;
		float:left;
		}
	.cuadnum{
		background-color:#00FFF3;
		padding:2px;
		margin:10px;
		text-align:center;
		font-family: 'Orbitron'; 
		font-size: 20px; 
		font-weight: bold;
		}
	.cuadnum_2{
		background-color:#00FFF3;
		padding:2px;
		margin:10px;
		text-align:center;
		font-family: 'Orbitron'; 
		font-size: 16px; 
		font-weight: bold;
		}
	.cuadnum_2b{
			background-color:#00FFF3;
			padding:2px;
			margin:10px;
			text-align:center;
			font-family: 'Orbitron'; 
			font-size: 14px; 
			font-weight: bold;
			}
	.cuadnum_uno{
		padding:20px;
		margin:10px;
		height:102px; 
		color:#FFFFFF;
		text-align:center;
		font-family:"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif; 
		font-size: 50px; 
		font-weight: bold;
		}
	div#rigth2{
		width: 130px;
		background-color:#000;
		float:left;
		}
		div#rigth2_4{
		width: 102px;
		background-color:#000;
		float:left;
		}
	div#left2{
		width: 130px;
		background-color:#000;
		float:left;
		}
	div#left2_4{
		width: 90px;
		background-color:#000;
		float:left;
	}
	div#left2b1{
		width: 130px;
		float:left;
		}
	div#lefto{
		width: 120px;
		float:left;
		padding:10px;
		border-radius: 20px 20px 20px 20px;
		border-style:1px solid;
		border:#00FFF3;
		}
	div#left2b1_4{
		width: 100px;
		float:left;
		}
		div#left2b1b{
		width: 150px;
		float:left;
		}
	div#left2b13{
		width: 130px;
		float:left;
		}
	div#left2b13_4{
		width: 100px;
		float:left;
		}
	div#left2b{
		width: 130px;
		}
	div#left2b_4{
		width: 90px;
		height: 60px;
		}
		div#left2bb{
		width: 130px;
		}
		div#left2bb4{
		width: 120px;
		}
	div#left2b3{
		width: 150px;
		}
	div#left2b3_4{
		width: 05px;
		}
	.estado{
		background-color:#0062A9;
		color:#FFFFFF;
		font-weight:bolder;
		font-family:Consolas, "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", monospace;
		font-size:13px;
		text-align:center;
		height:35px;
		}
	.estado2{
		background-color:#0062A9;
		color:#FFFFFF;
		font-weight:bolder;
		font-family:Consolas, "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", monospace;
		font-size:17px;
		text-align:center
		}
	div#center2{
		width: 120px;
		height:45px;
		/*background-color:#FFFFFF;*/
		align-content:center;
		float:left;
		}
	div#center3{
		width: 118px;
		height:45px;
		/*margin-left:11px;*/
		align-content:center;
		float:left;
		/*background: #D1D6DC;*/
		}
	.cuadnum2{
		background-color:#00FFF3;
		padding:4px;
		margin:10px;
		text-align:center;
		font-family: 'Orbitron'; 
		font-size: 18px; 
		font-weight: bold;
		}
	.cuadnum4{
		background-color:#00FFF3;
		padding:2px;
		margin:5px;
		text-align:center;
		font-family: 'Orbitron'; 
		font-size: 11px; 
		font-weight: bold;
		}
	.totalizador {
		width:425px; 
		padding-left:5px; 
		float:left; 
		align-content:center;
		margin-top:7px;
	}
	.totalizador4 {
		width:430px; 
		float:left; 
		align-content:center;
		margin-top:7px;
	}
	.surticara{
		color:#FFF;
		font-weight:bolder;
		font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
		font-size:22px;
		text-align:center;
		}
	.surticara4{
		color:#FFF;
		font-weight:bolder;
		font-family:"Orbitron", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
		font-size:35px;
		text-align:center;
		}
	.surticara2{
		color:#FFF;
		font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
		font-size:11px;
		text-align:center;
		}
}
