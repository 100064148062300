.no-overflow{
    overflow-y: initial !important;
}

.no-overflow div{
    overflow-y: initial !important;
}

.succes{
    background-color: green !important;
}

/* CSS Document */
@font-face {
    font-family: bebas;
    src: url(BebasNeue.ttf);
}

@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Code|Roboto+Mono&display=swap');

.labelbebas{
    color: #000;
	font-family: "Bebas Neue";
	font-size: 24px;
	text-align: center;
}
.labelbebasnit{
	font-family: "Bebas Neue";
	font-size: 18px;
	text-align: center;
}
.textnormal {
	font-family:"Roboto Mono";
	font-size: 13px;
}

#impresora{
	background-color: #FFFFFF; 
	margin-top: 30px; 
	text-align: center;
}
.body, .estrellita{
	font-size: 12px; 
	font-family: 'Roboto Mono', monospace; 
}
.tycondiciones{
	font-size: 8px; 
	font-family: 'Roboto Mono', monospace; 
}
.title{ 
	text-align: center;
	font-size: 16px; 
	letter-spacing: -0.1em;
	font-family: 'Fira Code', monospace;
}