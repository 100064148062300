.table td{
    padding: 5px !IMPORTANT;
    align-items: center;
}
.ttt th,td{
    border: 1px solid black;
    border-collapse: collapse;
}

.ttt2{
    padding: 5px;
  text-align: left;    
}

.numero{
	font-family: 'Roboto', sans-serif;
	font-size:18px;}
.numero2{
	font-family: 'Roboto', sans-serif !IMPORTANT;
	font-size:10px;}
	
.fontipo{
	color:#C60000;
	font-family: 'Raleway', sans-serif;
	font-size:11px;
    }
    
    .tb-terpel td{
        font-family: 'Raleway', sans-serif;
    font-size:11px;
    border: 0;
    padding: 0;
    }
    .tile {
        font-family: 'Raleway', sans-serif;
        font-family:11px;
        font-style:bold;
        text-color: #0000; 
        }