.logout  button{
    color: white;
    float: right;
    z-index: 2200;
}

.logout div{
    z-index: 2200;
}

.logout  button:hover{
    color: white;
    border: 1px white solid;
}