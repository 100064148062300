@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');
/* VISTA MOBIL*/
.login-img{
    padding: 0;
    background-repeat: no-repeat;
}

.img-firefuel{
  background-image: url("../assets/img/newFire.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.btn-login-firefuel{
  -moz-border-radius: 25px;
  -webkit-border-radius:25px;
  border: none;
  background-color: #FFA300;
  padding: 5px;
  width: 50%;
  -webkit-box-shadow: 11px 9px 24px -9px rgba(0,0,0,0.75);
-moz-box-shadow: 11px 9px 24px -9px rgba(0,0,0,0.75);
box-shadow: 11px 9px 24px -9px rgba(0,0,0,0.75);
}

.form-firefuel{
  background-image: url("../assets/img/fndloginpng.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/*inicio*/

.retro-button{
  position:relative;
  appearance:none;
  box-sizing:border-box;
  font-size:calc( var(--button-height) / 3 );
  font-family: 'Open Sans', sans-serif;
  background: var(--color-bg);
  height:var(--button-height);
  min-width:var(--button-height);
  border-radius:calc( var(--button-height) / 2);
  border:0;
  font-weight:800;
  text-transform:uppercase;
  color:var(--color-text);
  text-shadow: 1px 1px var(--color-text-shadow);
  cursor:pointer;
  margin:6px 6px;
  letter-spacing:.1em;
  transition: all 200ms ease;
  box-shadow:
    -1px  -1px 1px var(--color-bg),
    0 0 0 4px var(--color-overlay-medium), 
    1px  1px 1px var(--color-bg-dark), 
    inset .0 .0 .0 var(--color-overlay-dark),   
    inset .5rem .5rem .25rem var(--color-bg-light) 
}

.retro-button::-moz-focus-inner{
  outline:none;
  border:none;
}

.retro-button:focus{
  outline:none;
  box-shadow:
    -1px -1px 1px var(--color-bg-dark),
    0 0 0 4px var(--color-tab-focus), 
    1px  1px 1px var(--color-bg-dark), 
    inset 0 0 0 var(--color-overlay-dark),
    inset .5rem .5rem .25rem var(--color-bg-light), 
    ;
}

.retro-button:hover{
  box-shadow:
    -1px -1px 1px var(--color-bg-dark),
    0 0 0 4px var(--color-overlay-dark), 
    1px  1px 1px var(--color-bg-dark), 
    inset 0 0 0 var(--color-overlay-dark), 
    inset .5rem .5rem .25rem var(--color-bg-light), 
    ;
}

.retro-button:active{
  box-shadow:
    inset 1px 1px 1px var(--color-bg), 
    0 0 0 4px var(--color-overlay-dark), 
    inset -1px -1px 1px var(--color-bg-light),   
    inset .5rem .5rem .75rem var(--color-bg-dark), 
    inset .5rem .5rem .5rem var(--color-bg-light), 
}


/*fin*/

#search-wrapper {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-flow: row-reverse nowrap;
      -ms-flex-flow: row-reverse nowrap;
          flex-flow: row-reverse nowrap;
  width: 80%;
}

.transparencia-border{
  background-color: rgba(255,255,255,37%);
  border: 1px solid rgba(150,240,30,1);
  color: #ffffff;
}

.transparencia-border:focus{
  background-color: rgba(0,0,0,37%);
  border: 1px solid rgba(150,240,30,1);
  color: #ffffff;
}
.texto-login{
  font-family: 'Quicksand', sans-serif;
}
::placeholder{
  color: #ffffff;
}
#search-wrapper #search {
  border-left: 0;
  border-radius: 0 25px 25px 0;
  color: #444;
  padding: .6rem .5rem;
  font-size: 15px;
  font-weight: lighter;
  -webkit-transition: border-color .2s ease;
  transition: border-color .2s ease;
  width: 100%;
}
#search-wrapper #search + i {
  border-radius: 25px 0 0 25px;
  border-right: 0;
  color: #777;
  padding: .67rem .65rem;
  -webkit-transition: border-color .2s ease;
  transition: border-color .2s ease;
}


@media (max-width: 720px) {
  .login-img{
    padding: 0;
    background-repeat: no-repeat;
    display: none;
}
}

.form-login{   
    /*background-image: url('../assets/img/textura1.jpg');-*/
    background: rgba(237,237,237,1);
		background: -moz-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(237,237,237,1) 18%, rgba(217,217,217,1) 57%, rgba(155,154,155,1) 99%, rgba(154,152,154,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(237,237,237,1)), color-stop(18%, rgba(237,237,237,1)), color-stop(57%, rgba(217,217,217,1)), color-stop(99%, rgba(155,154,155,1)), color-stop(100%, rgba(154,152,154,1)));
		background: -webkit-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(237,237,237,1) 18%, rgba(217,217,217,1) 57%, rgba(155,154,155,1) 99%, rgba(154,152,154,1) 100%);
		background: -o-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(237,237,237,1) 18%, rgba(217,217,217,1) 57%, rgba(155,154,155,1) 99%, rgba(154,152,154,1) 100%);
		background: -ms-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(237,237,237,1) 18%, rgba(217,217,217,1) 57%, rgba(155,154,155,1) 99%, rgba(154,152,154,1) 100%);
		background: linear-gradient(to bottom, rgba(237,237,237,1) 0%, rgba(237,237,237,1) 18%, rgba(217,217,217,1) 57%, rgba(155,154,155,1) 99%, rgba(154,152,154,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#9a989a', GradientType=0 );
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.texto-contrasena{
  font-style: normal; font-size: 12px; color: rgb(94, 39, 0); font-weight: bold; text-align: center;
}
.boton_personalizado{
  text-decoration: none;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  border-radius: 6px;
  border: 1px solid #930000;
background: rgba(255,0,0,1);
background: -moz-linear-gradient(top, rgba(255,0,0,1) 0%, rgba(255,0,0,1) 18%, rgba(255,0,0,1) 57%, rgba(147,0,0,1) 99%, rgba(144,0,0,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,0,0,1)), color-stop(18%, rgba(255,0,0,1)), color-stop(57%, rgba(255,0,0,1)), color-stop(99%, rgba(147,0,0,1)), color-stop(100%, rgba(144,0,0,1)));
background: -webkit-linear-gradient(top, rgba(255,0,0,1) 0%, rgba(255,0,0,1) 18%, rgba(255,0,0,1) 57%, rgba(147,0,0,1) 99%, rgba(144,0,0,1) 100%);
background: -o-linear-gradient(top, rgba(255,0,0,1) 0%, rgba(255,0,0,1) 18%, rgba(255,0,0,1) 57%, rgba(147,0,0,1) 99%, rgba(144,0,0,1) 100%);
background: -ms-linear-gradient(top, rgba(255,0,0,1) 0%, rgba(255,0,0,1) 18%, rgba(255,0,0,1) 57%, rgba(147,0,0,1) 99%, rgba(144,0,0,1) 100%);
background: linear-gradient(to bottom, rgba(255,0,0,1) 0%, rgba(255,0,0,1) 18%, rgba(255,0,0,1) 57%, rgba(147,0,0,1) 99%, rgba(144,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#900000', GradientType=0 );
}
.boton_personalizado:hover{
  color: #ffffff;
  background: rgba(144,0,0,1);
background: -moz-linear-gradient(top, rgba(144,0,0,1) 0%, rgba(147,0,0,1) 1%, rgba(255,0,0,1) 43%, rgba(255,0,0,1) 82%, rgba(255,0,0,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(144,0,0,1)), color-stop(1%, rgba(147,0,0,1)), color-stop(43%, rgba(255,0,0,1)), color-stop(82%, rgba(255,0,0,1)), color-stop(100%, rgba(255,0,0,1)));
background: -webkit-linear-gradient(top, rgba(144,0,0,1) 0%, rgba(147,0,0,1) 1%, rgba(255,0,0,1) 43%, rgba(255,0,0,1) 82%, rgba(255,0,0,1) 100%);
background: -o-linear-gradient(top, rgba(144,0,0,1) 0%, rgba(147,0,0,1) 1%, rgba(255,0,0,1) 43%, rgba(255,0,0,1) 82%, rgba(255,0,0,1) 100%);
background: -ms-linear-gradient(top, rgba(144,0,0,1) 0%, rgba(147,0,0,1) 1%, rgba(255,0,0,1) 43%, rgba(255,0,0,1) 82%, rgba(255,0,0,1) 100%);
background: linear-gradient(to bottom, rgba(144,0,0,1) 0%, rgba(147,0,0,1) 1%, rgba(255,0,0,1) 43%, rgba(255,0,0,1) 82%, rgba(255,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#900000', endColorstr='#ff0000', GradientType=0 );
}

.wrapper{
    position: 'relative';
}

.buttonSuccess{
    background-color: green;
  }
  .buttonSuccess:hover{
    background-color: green;
  }

  .buttonProgress{
    color: green;
    position:absolute;
    top: '50%';
    left: '50%';
    margin-top: -12px;
    margin-left: -12px;
  }