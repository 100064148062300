.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}
.css-kj6f9i-menu{
z-index: '9999 !important';
position: fixed
}

.imgupload{
    background-image: url("../../assets/img/agregarfoto.png");
    background-size: 150px 150px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.bottom-aligned {
    display: flex;
    align-items: flex-end;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.userImg{
    background-image: url("../../assets/img/agregarfoto.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.textImg{
    background-image: url("../../assets/img/add_photo-512.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    cursor: pointer;
}
.categoriaImg{
    background-image: url("../../assets/img/Formularios/categoriaa.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.productoImg{
    background-image: url("../../assets/img/Formularios/foto_producto.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.categoriaImg:hover{
    background-image: url("../../assets/img/add_photo-512.png");
    background-color: rgba(0, 0, 0, 0.2)
}
.cardSeleccionado{
    -webkit-box-shadow: 0px 0px 38px -8px rgba(14,146,194,1);
-moz-box-shadow: 0px 0px 38px -8px rgba(14,146,194,1);
box-shadow: 0px 0px 38px -8px rgba(14,146,194,1);
}