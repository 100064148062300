@keyframes aparece-izquierda
{
    from { 
         transform: translateX(-100%); 
         opacity: 0;
            }
    to { 
         transform: translateX(0%); 
         opacity: 1;
            }
}
 
.ttt3 th,td{
     border: 0 ;
}

@keyframes aparece-derecha
{
    from { 
         transform: translateX(100%); 
         opacity: 0;
            }
    to { 
         transform: translateX(0%); 
         opacity: 1;
            }
}
.siguientePAaina
{
     animation: aparece-izquierda ease 1s backwards; 
}

.anteriorPagina
{
     animation: aparece-derecha ease 1s backwards; 
}