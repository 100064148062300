@charset "utf-8";
/* CSS Document */

td {
	font-family: 'Roboto', sans-serif;
	border: 0;
	}

.numero{
	font-family: 'Roboto', sans-serif;
	font-size:18px;}
.numero2{
	font-family: 'Roboto', sans-serif;
	font-size:11px;}
	
.fontipo{
	color:#666;
	font-family: 'Raleway', sans-serif;
	font-size:13px;
	}
.fontipo2{
	color:#C60000;
	font-family: 'Raleway', sans-serif;
	font-size:16px;
	}
	
.box_radious { 
	border:solid 1px #000000;  
	text-align:center; 
	border-radius: 10px 10px 10px 10px;
-moz-border-radius: 10px 10px 10px 10px;
-webkit-border-radius: 10px 10px 10px 10px;
border: 0px solid #000000;
}
.box { 
	border:solid 1px #CCCCCC;  
	text-align:center; 
	-moz-border-radius: 10px;  
	-webkit-border-radius: 10px; 
	border-radius: 10px;
}
	
.boxleft {
	border-top: 0px solid;
	border-right: 0px solid;
	border-bottom: 0px solid;
	border-left: 1px solid #FF7F00;
	text-align: center;
}
	
.numero2{
	font-family: 'Roboto', sans-serif !IMPORTANT;
	font-size:11px;}
		
.box2 { 
	border:solid 1px #FF7F00;  
	}

.search {
	width: 100%;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
	font-size:20px;
	size:20px;
	}
	
	
